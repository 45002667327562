import { TranslateService } from '@ngx-translate/core';
import deBase from './base/de.json';
import frBase from './base/fr.json';
import itBase from './base/it.json';
import deMedlist from './medlist/de.json';
import frMedlist from './medlist/fr.json';
import itMedlist from './medlist/it.json';

export function loadTranslations(service: TranslateService) {
  service.setTranslation('de-CH', deBase);
  service.setTranslation('de-CH', deMedlist, true);

  service.setTranslation('fr-CH', frBase);
  service.setTranslation('fr-CH', frMedlist, true);

  service.setTranslation('it-CH', itBase);
  service.setTranslation('it-CH', itMedlist, true);
}
